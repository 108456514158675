module.exports = (function () {
 	const burger = $('.burger');
	const nav = $('.navigation');
	const page = $('.page');
	
	burger.click(function () {
		$(this).toggleClass('active');
		nav.toggleClass('open');
		page.toggleClass('fixed');

	});
	$(".navigation a").on("click",function() {
		burger.removeClass('active');
		nav.removeClass('open');
		page.removeClass('fixed');
	});   

})();