module.exports = (function(){

  $('#sliderReviews').slick({
    dots: true,
    //autoplay: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 3,
    arrows: false,
    speed: 300,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1
      }
    }
    ]
  });  

  $(".next_btn-reviews").click(function(){
    $('#sliderReviews').slick("slickNext");
  });
   
  $(".prev_btn-reviews").click(function(){
    $('#sliderReviews').slick("slickPrev");
  });


  $(".section-works__item").find('.fancylink').fancybox({
    'transitionIn' : 'none',
    'transitionOut' : 'none'
  });
  
  if ($('#map')) {
    ymaps.ready(function () {
      const myMap = new ymaps.Map("map", {
        center: [53.720358, 23.824121], 
        zoom: 15,
        controls: []
      });
      myMap.behaviors.disable(['rightMouseButtonMagnifier', 'ruler']);   
      
      const myPlacemark = new ymaps.Placemark([53.718, 23.825], {}, {  
        iconLayout: 'default#image',
        iconImageHref: '/images/pinRed.svg',
        iconImageSize: [40, 40],
        iconImageOffset: [-30, -60]
      });
      myMap.geoObjects.add(myPlacemark);    
    });
  }  


  $("#form").submit(function(e) { 
    e.preventDefault();
    
		$.ajax({
			type: "POST",
      url: "send.php", 
      contentType: false,
      processData: false,
      data: new FormData(this),
      success: function(msg) {
        
        if (msg == 'ok') {
          $('.js-thank-you').text('Спасибо за заявку!');  
          $('.js-overlay-thank-you').fadeIn(200);
          setTimeout(function() {
            $('.js-overlay-thank-you').fadeOut(200); 
          }, 2000);      
          $('#form').trigger('reset'); 
        } else {
          $('.js-thank-you').text('Произошла ошибка');  
          $('.js-overlay-thank-you').fadeIn(200);
          setTimeout(function() {
            $('.js-overlay-thank-you').fadeOut(200); 
          }, 2000);
        }
      }
		});
  });
  
})();